/* eslint-disable @next/next/no-img-element */
import { Loader } from '@mantine/core';
import { CancelIcon } from 'common/assets';
import { AssetPaths } from 'common/constants/assets';
import { ResizeImage } from 'common/helpers/image';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ACCEPTED_FILE } from './main-file-picker';
import { fileStyles } from './styles.css';
import { onDrop } from './utils';
import Text from '../text';

function PreviewContent({ url, ratio }) {
  const splits = url?.split('?')[0].split('.');
  const extension = splits[splits?.length - 1]?.toLowerCase();

  const currentUrl = url.startsWith('blob:')
    ? splits.slice(0, splits.length - 1).join('.')
    : url;

  if (['jpeg', 'jpg', 'png'].includes(extension)) {
    return (
      <img
        alt={extension}
        src={currentUrl}
        className={fileStyles.imagePreview}
        {...(ratio
          ? {
              style: {
                width: '100%',
              },
            }
          : {})}
      />
    );
  }

  if (['pdf', 'xlsx', 'docx', 'doc', 'xls'].includes(extension)) {
    const Icon = (extension) => {
      switch (extension.extension) {
        case 'xls':
        case 'xlsx':
          return (
            <img
              alt={extension.extension}
              className={fileStyles.filePreview}
              src={AssetPaths.FileExcel}
            />
          );
        case 'doc':
        case 'docx':
          return (
            <img
              alt={extension.extension}
              className={fileStyles.filePreview}
              src={AssetPaths.FileWord}
            />
          );
        case 'pdf':
          return (
            <img
              alt={extension.extension}
              className={fileStyles.filePreview}
              src={AssetPaths.FilePdf}
            />
          );
        default:
          return (
            <img
              alt={extension.extension}
              className={fileStyles.filePreview}
              src={AssetPaths.FileGlobal}
            />
          );
      }
    };
    return (
      <div className={fileStyles.previewContainer}>
        <Icon extension={extension} />
      </div>
    );
  }

  return (
    <img
      alt={extension}
      src={AssetPaths.FileGlobal}
      className={fileStyles.filePreview}
    />
  );
}

export interface PreviewFilePickerProps {
  id?: string;
  name?: string;
  ratio?: number;
  preview: string;
  accept?: string;
  disabled?: boolean;
  resizeWidth: number;
  isMultiple?: boolean;
  resizeHeight: number;
  resizeImage?: boolean;
  onDelete?: () => void;
  onPicked: (files: { filename: string; url: string }[]) => void;
}

export default function PreviewFilePicker(props: PreviewFilePickerProps) {
  const {
    ratio,
    preview,
    disabled,
    onDelete,
    onPicked,
    isMultiple,
    resizeImage,
    resizeWidth,
    resizeHeight,
  } = props;
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const ResizeImageFunc = React.useCallback(
    async (file, type) => {
      const result: File = (await ResizeImage({
        type,
        width: resizeWidth,
        imageToResize: file,
        height: resizeHeight,
      })) as File;
      return result;
    },
    [resizeHeight, resizeWidth],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) =>
      onDrop({
        onPicked,
        isMultiple,
        resizeImage,
        setIsUploading,
        ResizeImageFunc,
        acceptedFiles: files,
      }),
  });

  //@ts-ignore
  const { onClick: openFilePicker, ...rootProps } = getRootProps({
    refKey: 'ref',
  });

  const onRootClick = React.useCallback(() => {
    if (!isUploading) {
      const splits = preview?.split('?')[0].split('.');
      const currentUrl = preview.startsWith('blob:') ? splits[0] : preview;
      window.open(currentUrl);
    }
  }, [isUploading, preview]);

  return (
    <div
      {...rootProps}
      onClick={onRootClick}
      className={fileStyles.previewContainer}
      {...(ratio
        ? {
            style: {
              width: '100%',
            },
          }
        : {})}
    >
      <div
        className={fileStyles.imagePreviewContainer}
        {...(ratio
          ? {
              style: {
                paddingTop: `${100 / ratio}%`,
                width: '100%',
              },
            }
          : {})}
      >
        {isUploading ? (
          <div className={fileStyles.loadingContainer}>
            <Loader size={20} color="blue" />
          </div>
        ) : (
          <>
            <PreviewContent url={preview} ratio={ratio} />
            {!disabled && (
              <>
                <div
                  className={fileStyles.removeImage}
                  onClick={(e) => {
                    onDelete?.();
                    e.stopPropagation();
                  }}
                >
                  <CancelIcon />
                </div>
                <div
                  className={fileStyles.editComponent}
                  onClick={(e) => {
                    openFilePicker && openFilePicker(e);
                    e.stopPropagation();
                  }}
                >
                  <Text textVariant="body2Semibold" style={{ color: 'white' }}>
                    {t('common:edit')}
                  </Text>
                </div>
              </>
            )}
            <input
              {...getInputProps()}
              id={props.id}
              name={props.name}
              disabled={disabled}
              accept={props.accept || ACCEPTED_FILE}
            />
          </>
        )}
      </div>
    </div>
  );
}
