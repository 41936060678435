import { SignOut } from '@phosphor-icons/react';
import {
  StoreIcon,
  NotebookIcon,
  SimIcon,
  GiftIcon,
  ProfileIcon,
  NotificationIcon,
  ChatIcon,
} from 'common/assets';
import { CustomValue } from 'common/repositories/customization';
import { NavigationRoutes } from 'common/routes';
import colors from 'common/styles/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useLiveChatAppActionDataProvider,
  useLogoutAppActionDataProvider,
  useNotificationAppActionDataProvider,
} from './data-provider';
import { AppActionType, IAppAction } from './types';

export enum DefaultAppLayoutActionKey {
  Store = 'store',
  Orders = 'orders',
  MySim = 'my-sim',
  Referral = 'referral',
  Profile = 'profile',
  Notifications = 'notifications',
  ContactUs = 'contact-us',
  Logout = 'logout',
}

export const AppLayoutActionsContext = React.createContext<IAppAction[]>([]);

export function DefaultAppLayoutActionsProvider({
  children,
}: React.PropsWithChildren) {
  const { t } = useTranslation();
  const defaultActions = React.useMemo<IAppAction[]>(
    () => [
      {
        key: DefaultAppLayoutActionKey.Store,
        children: t('home:store'),
        icon: StoreIcon,
        route: NavigationRoutes.Store,
        type: AppActionType.Navigation,
        routePrefix: [NavigationRoutes.Search, NavigationRoutes.Purchase],
      },
      {
        key: DefaultAppLayoutActionKey.Orders,
        children: t('common:orders'),
        icon: NotebookIcon,
        route: NavigationRoutes.Orders,
        type: AppActionType.Navigation,
        routePrefix: [NavigationRoutes.Orders, NavigationRoutes.OrderDetail],
      },
      {
        key: DefaultAppLayoutActionKey.MySim,
        children: t('home:my_sim'),
        route: NavigationRoutes.MySim,
        icon: SimIcon,
        type: AppActionType.Navigation,
        routePrefix: [
          NavigationRoutes.MySim,
          NavigationRoutes.Esim,
          NavigationRoutes.ArchiveEsim,
          NavigationRoutes.Recharge,
        ],
      },
      {
        key: DefaultAppLayoutActionKey.Referral,
        route: NavigationRoutes.Referral,
        children: t('common:referral'),
        icon: GiftIcon,
        type: AppActionType.Navigation,
        routePrefix: [NavigationRoutes.Referral],
      },
      {
        key: DefaultAppLayoutActionKey.Profile,
        route: NavigationRoutes.Profile,
        children: t('home:profile'),
        icon: ProfileIcon,
        type: AppActionType.Navigation,
        routePrefix: [
          NavigationRoutes.Profile,
          NavigationRoutes.AffiliateRegistration,
          NavigationRoutes.AffiliateAccountSettings,
        ],
      },
      {
        key: DefaultAppLayoutActionKey.Notifications,
        route: NavigationRoutes.Notifications,
        children: t('common:notification'),
        icon: NotificationIcon,
        useProvideData: useNotificationAppActionDataProvider,
        type: AppActionType.Regular,
      },
      {
        key: DefaultAppLayoutActionKey.ContactUs,
        children: t('profile:contact_us'),
        icon: ChatIcon,
        useProvideData: useLiveChatAppActionDataProvider,
        type: AppActionType.Regular,
      },
      {
        key: DefaultAppLayoutActionKey.Logout,
        children: t('common:logout'),
        icon: SignOut,
        useProvideData: useLogoutAppActionDataProvider,
        sidenavCustomization: {
          icon: {
            color: CustomValue.overwrite(colors.sentimentNegative),
          },
          text: {
            textColor: CustomValue.overwrite('sentimentNegative'),
          },
        },
        type: AppActionType.Convenience,
      },
    ],
    [t],
  );
  return (
    <AppLayoutActionsContext.Provider value={defaultActions}>
      {children}
    </AppLayoutActionsContext.Provider>
  );
}
