import { CheckIcon, WarningCircleIcon } from 'common/assets';
import size from 'common/styles/size';
import { useContext, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormContext } from '../form/context';
import PasswordInput, { PasswordInputProps } from '../password-input';
import Text from '../text';

const iconSize = 20;

interface StrengthProps {
  requirements: RequirementProps[];
  onPasswordStrong?: (isStrong: boolean) => void;
}

function PasswordStrength(props: StrengthProps) {
  const { requirements, onPasswordStrong } = props;
  const { t } = useTranslation();

  const listLength = requirements.length;

  const checkedLength = requirements.filter(
    (requirement) => requirement.isMetCondition !== false,
  ).length;

  const isStrong = listLength === checkedLength;

  useEffect(() => {
    if (onPasswordStrong && isStrong) {
      onPasswordStrong(isStrong);
    }
  }, [isStrong, onPasswordStrong]);

  return (
    <Text
      textVariant="body2Regular"
      style={styles.itemContainerStyle}
      textColor={isStrong ? 'sentimentPositive' : 'sentimentNegative'}
    >
      {isStrong ? (
        <CheckIcon size={iconSize} style={styles.mR10} />
      ) : (
        <WarningCircleIcon size={iconSize} style={styles.mR10} />
      )}
      {t('common:password_strength', {
        extra: isStrong ? t('common:excellent') : t('common:weak'),
      })}
    </Text>
  );
}

interface RequirementProps {
  label: string;
  isMetCondition: boolean;
}

function PasswordRequirement(props: RequirementProps) {
  const { label, isMetCondition } = props;

  return (
    <Text
      textVariant="body2Regular"
      style={styles.itemContainerStyle}
      textColor={isMetCondition ? 'sentimentPositive' : 'foregroundTertiary'}
    >
      <CheckIcon size={iconSize} style={styles.mR10} />
      {label}
    </Text>
  );
}

export interface PasswordInputFieldProps extends PasswordInputProps {
  name: string;
  type: 'password';
  checkStrength?: boolean;
  requirements?: (value: string) => RequirementProps[];
  strengthName?: string;
  onAfterChange?: (value: string) => void;
}

export default function PasswordInputField(props: PasswordInputFieldProps) {
  const {
    name,
    disabled: _disabled,
    readOnly,
    type,
    required,
    checkStrength = false,
    requirements,
    noMargin = false,
    strengthName = '',
    onAfterChange,
    ...rest
  } = props;

  const [focus, setFocus] = useState<boolean>(false);

  const context = useContext(FormContext);
  const { control } = useFormContext<any>();
  const {
    field: { onChange, value, ...restField },
    fieldState,
  } = useController({ name, control });
  const { field: strengthField } = useController({
    name: strengthName,
    control,
  });

  const disabled = !context.editable || readOnly || _disabled;
  const error = fieldState?.error?.message;

  const requirementList =
    requirements &&
    requirements(value).map((requirement, index) => (
      <PasswordRequirement
        key={index}
        label={requirement.label}
        isMetCondition={requirement.isMetCondition}
      />
    ));

  return (
    <>
      <PasswordInput
        noMargin
        {...rest}
        {...restField}
        {...(!disabled && { required })}
        value={value}
        error={error}
        disabled={disabled}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        onChange={(e) => {
          onChange(e.target.value);
          onAfterChange?.(e.target.value);
        }}
      />
      {!!checkStrength && !!focus && (
        <>
          <PasswordStrength
            requirements={requirements?.(value) ?? []}
            onPasswordStrong={strengthField.onChange}
          />
          {requirementList}
        </>
      )}
      {!noMargin && <div style={{ marginBottom: size.spacing.md }} />}
    </>
  );
}

const styles: any = {
  itemContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  mR10: {
    marginRight: 10,
  },
};
