import { Loader } from '@mantine/core';
import { hideNotification } from '@mantine/notifications';
import notification from 'common/helpers/notification';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import colors from 'common/styles/colors';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import { usePolling } from 'hooks/use-timer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { errorViewStyles } from './style.css';
import Separator from '../separator';

export interface ErrorViewComponentProps {
  isLoading?: boolean;
  title?: string;
  message?: string;
  refetch?: () => void;
  customization?: {
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
  };
}

export default function ErrorViewComponent(props: ErrorViewComponentProps) {
  const { t } = useTranslation();
  const { title, message, isLoading, refetch, customization } = props;

  return (
    <div
      {...applyCustomization({ className: errorViewStyles.container }, [
        customization?.root,
      ])}
      onClick={() => {
        if (!isLoading) {
          refetch && refetch();
        }
      }}
    >
      <div className={errorViewStyles.content}>
        <Text textVariant="h3">{title || t('error:load_failed_title')}</Text>
        {message && (
          <Text textVariant="body1Regular" textColor="sentimentNegative">
            {message}
          </Text>
        )}
        <Separator gap={8} />
        {isLoading ? (
          <Loader size={30} />
        ) : (
          <Button
            variant={{
              variant: 'tertiary',
              size: 'defaultLink',
            }}
          >
            {t('error:load_failed_prompt')}
          </Button>
        )}
      </div>
    </div>
  );
}

interface UseNotifyTooManyAttemptsErrorProps {
  error: any;
  refetch?(): void;
}

const TOO_MANY_ATTEMPTS_NOTIF_ID = 'too-many-attempts';
export function useNotifyTooManyAttemptsError(
  props: UseNotifyTooManyAttemptsErrorProps,
) {
  const { error, refetch } = props;
  // Show too many attempts
  const [active429, setActive429] = React.useState(false);

  React.useEffect(() => {
    if (error && error.statusCode === 429) {
      notification.error({
        message: error.message,
        color: colors.sentimentNegative,
        loading: true,
        autoClose: false,
        id: TOO_MANY_ATTEMPTS_NOTIF_ID,
      });
      setActive429(true);
    }
  }, [active429, error]);

  React.useEffect(() => {
    if (active429 && !error) {
      setActive429(false);
      hideNotification(TOO_MANY_ATTEMPTS_NOTIF_ID);
    }
  }, [active429, error]);

  React.useEffect(() => {
    return () => {
      hideNotification(TOO_MANY_ATTEMPTS_NOTIF_ID);
    };
  }, []);

  usePolling({
    fn: refetch!,
    enabled: active429 && !!error && !!refetch,
    interval: 10000,
  });

  return {
    showError: error?.statusCode !== 429,
  };
}
