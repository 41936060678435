import { CheckCircleIcon } from 'common/assets';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from '../button';
import { FormContext } from '../form/context';

export interface ButtonFieldProps extends ButtonProps {
  type: 'submit';
  text?: string;
  hideIcon?: boolean;

  /** Disallows user to submit when the form is clean. Default is set to false. Set this to true for forms that are used for editing. */
  noClean?: boolean;
}

export default function ButtonField(props: ButtonFieldProps) {
  const { t } = useTranslation();
  const {
    disabled: _disabled,
    text = t('common:save'),
    hideIcon = true,
    children,
    noClean,
    ...rest
  } = props;
  const {
    formState: { isSubmitting, errors, isDirty },
  } = useFormContext();
  const { editable } = useContext(FormContext);

  if (!editable && !isSubmitting) {
    return null;
  }

  const isValid = isEmpty(errors);
  const disabled = _disabled || !isValid || isSubmitting;

  return (
    <Button
      {...rest}
      disabled={disabled || (noClean && !isDirty)}
      leftSection={!hideIcon ? <CheckCircleIcon size={16} /> : undefined}
      loading={isSubmitting}
    >
      {children ?? text ?? t('common:submit')}
    </Button>
  );
}
