import { useValidatePhone } from 'api-hooks/common';
import { ReplaceKeys } from 'common/utils/types';
import Button from 'components/elements/button';
import { BottomSheetRemote } from 'components/widgets/bottom-sheet';
import { useDebouncedEffect } from 'hooks/debounce';
import { useBindValue } from 'hooks/use-bind-value';
import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import structuralStyles from 'styles/layout.css';

import CountrySelectBottomSheet from './bottom-sheet';
import { FormContext, useFormState } from '../../elements/form/context';
import Text from '../../elements/text/base';
import TextInput, { TextInputProps } from '../../elements/text-input/base';

type PhoneNumberInputProps = ReplaceKeys<
  TextInputProps,
  {
    onChange?(phone: string): void;
    onFailedValidation?(err: string | undefined): void;
    editPrefix?: boolean;
  }
>;

const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  PhoneNumberInputProps
>(function (props, ref) {
  const {
    onChange,
    onFailedValidation,
    value,
    label,
    noMargin,
    required,
    editPrefix = false,
    ...restProps
  } = props;
  const controlledRawValue = props.value
    ? props.value.toString().split(' ', 2)
    : [];
  const controlledPrefix =
    controlledRawValue.length >= 2
      ? controlledRawValue[0]
      : controlledRawValue.length === 0
        ? '+62'
        : '';
  const controlledPhone =
    controlledRawValue.length >= 2
      ? controlledRawValue[1]
      : controlledRawValue[0];
  const [prefix, setPrefix] = React.useState(controlledPrefix ?? '+62');
  const [phone, setPhone] = React.useState(controlledPhone ?? '');
  const remote = React.useRef<BottomSheetRemote | null>(null);

  const { editable } = useFormState();

  useBindValue(setPrefix, controlledPrefix ?? '');
  useBindValue(setPhone, controlledPhone ?? '');
  useBindValue((val) => props.onChange?.(val), `${prefix} ${phone}`);

  const { mutate: validatePhone, error: phoneValidateError } =
    useValidatePhone();

  React.useEffect(() => {
    onFailedValidation?.(phoneValidateError?.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneValidateError]);

  const hasIgnoredMountEffect = React.useRef(false);
  useDebouncedEffect(
    () => {
      if (!hasIgnoredMountEffect.current) {
        hasIgnoredMountEffect.current = true;
        return;
      }
      if (!phone) {
        onFailedValidation?.(undefined);
        onChange?.('');
        return;
      }
      validatePhone({
        phoneNumber: `${prefix} ${phone}`,
      });
    },
    [prefix, phone],
    800,
  );

  return (
    <div>
      <Text textVariant="body1Regular">
        {label}
        {!!required && (
          <Text span textColor="backgroundNotification">
            &nbsp;*
          </Text>
        )}
      </Text>
      <div className={structuralStyles.flexbox({ gap: 8, align: 'start' })}>
        <Button
          variant={{
            variant: 'secondary',
          }}
          disabled={!editable && !editPrefix}
          onClick={() => remote.current?.open()}
          miw={80}
          h={42}
        >
          {prefix}
        </Button>
        <TextInput
          value={phone}
          ref={ref}
          onChange={(e) => setPhone(e.target.value.replaceAll(/[^0-9]+/g, ''))}
          error={phoneValidateError?.message}
          {...restProps}
          pattern="^[0-9]+$"
          w="100%"
          noMargin={noMargin}
        />
      </div>
      <CountrySelectBottomSheet
        onChange={(country) => setPrefix(country.dialCode)}
        trailingType="dialCode"
        ref={remote}
      />
    </div>
  );
});

export default PhoneNumberInput;

export interface PhoneNumberInputFieldProps extends TextInputProps {
  name: string;
  type: 'tel';
  onAfterChange?(value: string): void;
}

export function PhoneNumberInputField(props: PhoneNumberInputFieldProps) {
  const {
    name,
    disabled: _disabled,
    readOnly,
    type,
    required,
    onAfterChange,
    ...rest
  } = props;

  const context = React.useContext(FormContext);
  const { control, setError, clearErrors } = useFormContext<any>();
  const {
    field: { onChange, ...restField },
    fieldState,
  } = useController({ name, control });

  const disabled = !context.editable || readOnly || _disabled;
  const error = fieldState?.error?.message;

  return (
    <PhoneNumberInput
      type="tel"
      {...rest}
      {...restField}
      {...(!disabled && { required })}
      onFailedValidation={(err) => {
        if (err) {
          setError(name, {
            message: err,
            type: 'manual',
          });
        } else {
          clearErrors(name);
        }
      }}
      error={error}
      disabled={disabled}
      onChange={(e) => {
        onChange(e);
        onAfterChange?.(e);
      }}
    />
  );
}
