import useDetectDevice from 'hooks/use-detect-device';
import useGetBottomSheetScrollContainer from 'hooks/use-get-bottom-sheet-scroll-container';
import useGetParentRef, { ParentRefType } from 'hooks/use-parent-ref';
import React from 'react';

export interface VirtualizedComponentProps {
  children: React.ReactNode;
  maxWidth: number;
  maxHeight: number;
  threshold?: number;
  type?: 'default' | 'bottom-sheet';
}

// used for app
export default function VirtualizedComponent(props: VirtualizedComponentProps) {
  const { type = 'default' } = props;
  const { isKurosimApp, isMobile } = useDetectDevice();
  const { maxHeight, maxWidth, threshold = 1 } = props;
  const [visible, setVisible] = React.useState(false);
  const containerRef = useGetParentRef(ParentRefType.Scroll);
  const ref = React.useRef<HTMLDivElement>(null);
  const scrollRef = useGetBottomSheetScrollContainer();
  React.useEffect(() => {
    const curr = ref.current;
    //if we used the virtualized component on web, user can't find the component with ctrl + f
    if (!(isKurosimApp || isMobile)) {
      setVisible(true);
      return;
    }
    const root = type === 'default' ? null : scrollRef;
    if (!curr) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      {
        root,
        rootMargin: '0px',
        threshold,
      },
    );
    observer.observe(curr);
    return () => {
      observer.unobserve(curr);
    };
  }, [containerRef, isKurosimApp, isMobile, ref, scrollRef, threshold, type]);

  const content = props.children;
  return (
    <div
      ref={ref}
      style={{
        minHeight: visible ? undefined : maxHeight,
        width: '100%',
        height: visible ? 'fit-content' : '100%',
        maxHeight,
        maxWidth,
      }}
    >
      {visible ? content : null}
    </div>
  );
}
